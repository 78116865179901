import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import { Chip, Stack } from "@mui/material";
import { Calendar, type DateRange } from "@src/appV2/lib";
import { useMonthRange } from "@src/appV2/lib/Calendar/useMonthRange";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { addWeeks, format, startOfMonth, startOfWeek, subDays } from "date-fns";
import { type ReactElement } from "react";

import { OpenShiftCell } from "./Cells";

export function OpenShiftsCalendarSkeleton(): ReactElement {
  const currentDate = new Date();

  const { dateRange } = useMonthRange({
    startDate: currentDate,
  });

  const calendarDateRange: DateRange = {
    startDate: startOfWeek(dateRange.startDate),
    endDate: subDays(addWeeks(startOfWeek(dateRange.startDate), 6), 1),
  };

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center" marginBottom={1}>
        <Chip
          disabled
          color="primary"
          icon={<FilterIcon />}
          aria-label="Update Distance"
          label={<b style={{ visibility: "hidden" }}>150 miles</b>}
        />
      </Stack>

      <Calendar
        isLoading
        dateRange={calendarDateRange}
        options={{
          headerTitle: format(dateRange.startDate, "MMMM yyyy"),
          minDate: startOfWeek(startOfMonth(currentDate)),
          maxDate: addWeeks(startOfWeek(startOfMonth(currentDate)), 6),
        }}
        DateCell={({ value }) => {
          return (
            <OpenShiftCell
              isDisabled
              isLoading
              date={value}
              openShiftsForDay={{ total: 0, slots: [], priorityAccess: [] }}
              agentShiftsForDay={{ [ShiftPeriod.AM]: 0, [ShiftPeriod.PM]: 0, [ShiftPeriod.NOC]: 0 }}
            />
          );
        }}
        onPrevious={() => null}
        onNext={() => null}
      />
    </>
  );
}
